<template>
  <!-- :append-icon="pending?'mdi-circle':''" -->
  <button
    v-if="props.itemId"
    class="btn uppercase hover:font-bold w-100 h-full bg-primary text-primary-content btnstyle_shimmer"
    @click="addProductToCart(itemId, variantId)"
  >
    Add To Cart
    <!-- {{ variant.cartQty>0 ? `${variant.cartQty} In Cart`: 'Add To Cart' }} -->
  </button>
  <div 
    v-if="outOfStockSnackbar" 
    class="snackbar elevation-10"
  >
    <span>Sorry, this item is currently out of stock.</span>
  </div>
  <div 
    v-if="addToCartSnackbar"
  >
    <div class="snackbar elevation-10">
      <p class="text-wrap">
        This item has been added to your cart.
      </p>
      <NuxtLink to="/checkout">
        <button class="btn-sm font-bold text-lg">
          VIEW CART
        </button>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
//TODO
//We should add an item to the wishlist any time there are problems adding to cart
import { useUserDataStore } from '~/stores/userData'
import { find } from 'lodash-es'

const props = defineProps({
  itemId:{
    type:String,
    required:true
  },
  variantId:{
    type:Number,
    required:true
  }
});
const itemId = computed({
  get() {
    return props.itemId
  }
})
const variantId = computed({get(){return props.variantId}})
const snackbar = ref()
const addToCartSnackbar = ref(false)
const outOfStockSnackbar = ref(false)
const pending = ref(false)

const addProductToCart = (itemId, variantId) => {
  //not currently working :()
  pending.value = true
  // console.log(`"Adding itemID,variantID: ${itemId}, ${variantId}"`)
  
  const match = find(useUserDataStore().userData.cart,(item)=> {return item.productId==itemId && item.variantId==variantId}) //find products with item and variant ID already in cart
  if(match){
    match.quantity++

    //TODO
    //We may also want to display the snackbar and say like... #x item in cart
    // snackbar.value = true
    pending.value = false
  }
  else{
    //item not found, make it new
    makeNewProduct(itemId,variantId)
  }
  snackbarFn()
}

const snackbarFn = async()=>{
  addToCartSnackbar.value = true
  await delay(5000)
  addToCartSnackbar.value = false
}


const makeNewProduct = async (itemId,variantId) =>{
  //Right now this is always a printify product but this will soon need yet another revamp
  const fetcher = await $fetch('/api/printify/product?id='+itemId,{method:'GET'})
  const variant = find(fetcher.variants,(variant)=>{return variant.id===variantId})
  if(variant===undefined){
    console.log("No matching variant found")
    outOfStockSnackbar.value=true
  }

  if(fetcher.visible){
    let cartBody = {
      productId:itemId,
      productTitle:fetcher.title,
      variantId:variantId,
      variantTitle:variant.title,
      photoURL:fetcher.images[0].src,
      quantity:1,
      price:variant.price,
      shipCost:variant.shipCost,
      sku:variant.sku
    }
    // console.log("Pushing data to cart")
    // console.log(cartBody)
    useUserDataStore().userData.cart.push(cartBody)
    snackbar.value = true
    pending.value = false
    await delay(3210)
    snackbar.value = false
  } else{
    outOfStockSnackbar.value=true
  }
}

const delay = ms => new Promise(res => setTimeout(res, ms));
</script>

<style scoped>
.btnstyle_shimmer {
  /* background: -moz-linear-gradient(-45deg, oklch(var(--s)) 0%, oklch(var(--s)) 40%, #ffffff 50%, oklch(var(--s)) 60%, oklch(var(--s)) 100%); */
  /* background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgb(var(--v-theme-primary))), color-stop(40%,rgb(var(--v-theme-primary))), color-stop(50%,#ffffff), color-stop(60%,rgb(var(--v-theme-primary))), color-stop(100%,rgb(var(--v-theme-primary)))); */
  /* background: -webkit-linear-gradient(-45deg,  oklch(var(--s)) 0%,oklch(var(--s)) 40%,#ffffff 50%,oklch(var(--s)) 60%,oklch(var(--s)) 100%); */
  /* background: -o-linear-gradient(-45deg,  oklch(var(--s)) 0%,oklch(var(--s)) 40%,#ffffff 50%,oklch(var(--s)) 60%,oklch(var(--s)) 100%); */
  /* background: -ms-linear-gradient(-45deg,  oklch(var(--s)) 0%,oklch(var(--s)) 40%,#ffffff 50%,oklch(var(--s)) 60%,oklch(var(--s)) 100%); */
  background: linear-gradient(135deg,  oklch(var(--p)) 0%,oklch(var(--p)) 40%,#ffffff 50%,oklch(var(--p)) 60%,oklch(var(--p)) 100%);
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgb(var(--v-theme-primary))', endColorstr='rgb(var(--v-theme-primary))',GradientType=1 ); */
  background-repeat: no-repeat;  
  background-position:0px;
  background-size:300%;  
}

.btnstyle_shimmer:hover{
  animation:light 2s;
  -webkit-animation:light 1s; 

}

.btnstyle_shimmer:active {
 box-shadow: 0 3px 15px -2px rgba(0,0,0,.85);
 transform: scale(.9);
}


@keyframes light {
  0% {
        background-position:-600px; 
  }
  100% { 
        background-position:0px; 
  }
}
        
@-webkit-keyframes light {
  0% {
        background-position:-600px; 
  }
  100% { 
        background-position:0px; 
  }
}
</style>